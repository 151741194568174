import React, { useState } from 'react';
import { withFirebase } from "../../components/Firebase";
import Avatar from '@material-ui/core/Avatar';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { ActivityButton, AlertDialog, ErrorDialog } from '..';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://material-ui.com/">
        WDG Enterprises Ltd
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

function ForgotPassword(props) {
  const classes = useStyles();

  const [errors, setError] = useState("")
  const [openError, setOpenError] = useState(false)
  const [openAlert, setOpenAlert] = useState(false)
  const [alertMessage, setAlertMessage] = useState(false)
  const [alertTitle, setAlertTitle] = useState(false)
  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)
  const [email, setEmail] = useState(props.signInProps.email)

  const handleErrorClose = () => {
    setOpenError(false)
  }

  const handleAlertClose = () => {
    setOpenAlert(false)
    props.onClick('SignIn')
  }

  const onSubmit = event => {
    setLoading(true)
    props.firebase
      .sendResetEmail(email)
      .then(() => {
        setLoading(false)
        setSuccess(true)
        setAlertTitle("Email sent")
        setAlertMessage("We've sent an email to your inbox. (Please check spam folder too) - We will redirect you to the sign in page.")
        setOpenAlert(true)
      })
      .catch(err => {
        setLoading(false)
        setError(err.message);
        setOpenError(true)
      })


    if (event) event.preventDefault()
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Forgot Password?
        </Typography>
        <form className={classes.form} noValidate>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    onSubmit()
                  }
                }}
              />
            </Grid>
          </Grid>
          <ActivityButton
            onClick={onSubmit}
            success={success}
            loading={loading}
            fullWidth={true}
            variant="contained"
            color="primary"
            className={classes.submit}
            buttonName="Send password reset email"
          />
          <Grid container justify="flex-start">
            <Grid item>
              <Link variant="body2" onClick={() => props.onClick('SignIn')} style={{cursor: "pointer"}}>
                Back to Sign in
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
      <Box mt={5}>
        <Copyright />
      </Box>
      <ErrorDialog handleClose={handleErrorClose} openDialog={openError} errorMessage={errors} />
      <AlertDialog handleClose={handleAlertClose} openDialog={openAlert} message={alertMessage} title={alertTitle} />
    </Container>
  );
}

export default withFirebase(ForgotPassword)
