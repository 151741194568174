import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { compose } from "recompose";

import { Grid, Typography } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import AuthUserContext from "../../components/Session/context";
import { XpList, NewXp } from "../../components/Xp";
import { withFirebase } from "../../components/Firebase";
import { Loader } from "../../components";

import * as ROLES from "../../constants/roles";

const styles = {
  textCenter: {
    textAlign: "center"
  }
};

class XpPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      rewards: []
    };
  }

  componentDidMount() {
    this.setState({ loading: true });
    this.props.firebase
      .xprewards()
      .orderBy("name")
      .where("archived", "==", false)
      .onSnapshot(snapshot => {
        var rewards = [];
        snapshot.docs.forEach(doc => {
          rewards.push({
            ...doc.data(),
            id: doc.id
          });
        });
        this.setState({
          rewards: rewards,
          loading: false
        });
      });
  }

  archiveReward = rewardId => {
    this.props.firebase
      .xprewards()
      .doc(rewardId)
      .update({ archived: true });
  };

  render() {
    const { rewards, loading } = this.state;
    const { classes } = this.props;

    return (
      <AuthUserContext.Consumer>
        {authUser => (
          <>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <Typography variant="h2">XP Rewards</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1">
                  Below is a list of the types of XP awards that can be given to
                  people. Only items on this list can be awarded.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                {loading && <Loader />}
                {!loading && rewards.length === 0 && (
                  <>
                    <Grid item xs={12} className={classes.textCenter}>
                      <Typography variant="h2">
                        <FontAwesomeIcon icon="sad-tear" />
                      </Typography>
                    </Grid>
                    <Grid item xs={12} className={classes.textCenter}>
                      <Typography variant="subtitle1">
                        No rewards found
                      </Typography>
                    </Grid>
                  </>
                )}
                {!loading && rewards.length > 0 && (
                  <XpList
                    rewards={rewards}
                    showAdminFunctions={!!authUser.roles[ROLES.ADMIN]}
                    archiveReward={this.archiveReward}
                  />
                )}
              </Grid>
            </Grid>
            {!!authUser.roles[ROLES.ADMIN] && <NewXp />}
          </>
        )}
      </AuthUserContext.Consumer>
    );
  }
}

export default compose(withFirebase, withStyles(styles))(XpPage);
