import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Avatar from "@material-ui/core/Avatar";
import BusinessIcon from "@material-ui/icons/Business";

function OrganisationList(props) {
  const cell = {
    padding: "10px"
  };
  var { organisations } = props;
  return (
    <>
      <Table aria-label="Organisations">
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell>Name</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {organisations.map(org => (
            <TableRow key={org.id}>
              <TableCell padding="checkbox" style={cell}>
                <Avatar>
                  <BusinessIcon />
                </Avatar>
              </TableCell>
              <TableCell>{org.name}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  );
}

export default OrganisationList;
