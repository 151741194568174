import React, { Component } from "react";
import Avatar from "@material-ui/core/Avatar";
import Grid from "@material-ui/core/Grid";
import Chip from "@material-ui/core/Chip";

import { withFirebase } from "../Firebase";

class MySpecialitiesPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      specialities: []
    };
  }

  componentDidMount() {
    this.setState({ loading: true });

    this.unsubscribe = this.props.firebase
      .userSpecialities(this.props.userId)
      .onSnapshot(snapshot => {
        var specialities = [];
        snapshot.docs.forEach(doc => {
          specialities.push({
            ...doc.data(),
            id: doc.id
          });
        });
        this.setState({
          specialities: specialities,
          loading: false
        });
      });
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  render() {
    const { specialities, loading } = this.state;

    return (
      <div>
        {loading && <div>Loading...</div>}
        <SpecialitiesList loading={loading} specialities={specialities} />
      </div>
    );
  }
}

const SpecialitiesList = ({ specialities, loading }) => (
  <Grid container>
    {specialities.map(specialities => (
      <Grid item xs={12} key={specialities.id}>
        <Chip
          avatar={<Avatar>1</Avatar>}
          label={specialities.name}
          color="primary"
        />
      </Grid>
    ))}
  </Grid>
);

export default withFirebase(MySpecialitiesPage);
