import { useState, useEffect } from "react";
import firebase from "firebase/app";

import * as ROLES from "../../constants/roles";

const useUserDetails = userId => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [user, setUser] = useState(null);

  useEffect(() => {
    if (userId) {
      const unsubscribe = firebase
        .firestore()
        .collection("users")
        .doc(userId)
        .onSnapshot(
          snapshot => {
            if (snapshot.exists) {
              setUser(snapshot.data());
            } else {
              setError("User does not exist");
            }
            setLoading(false);
          },
          err => {
            setError(err);
          }
        );
      return unsubscribe;
    }
  }, [userId]);

  function toggleAdmin() {
    let roles = {};
    roles[ROLES.ADMIN] = user.roles && user.roles[ROLES.ADMIN] ? false : true;
    firebase
      .firestore()
      .collection("users")
      .doc(userId)
      .update({
        roles: roles
      })
      .catch(setError);
  }

  return {
    loading,
    error,
    user,
    toggleAdmin
  };
};

export default useUserDetails;
