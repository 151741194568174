import React from "react";
import { compose } from "recompose";

import { withFirebase } from "../Firebase";
import { withSnackbar } from "notistack";

import EnhancedTableToolbar from "./EnhancedTableToolbar";
import AchievementPicker from "../Achievements/Picker";
import RewardPicker from "../Xp/Picker";
import * as ROUTES from "../../constants/routes";
import * as ROLES from "../../constants/roles";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Checkbox from "@material-ui/core/Checkbox";
import Avatar from "@material-ui/core/Avatar";
import Link from "@material-ui/core/Link";
import Badge from "@material-ui/core/Badge";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { withStyles } from "@material-ui/core/styles";
import { amber } from "@material-ui/core/colors";

const styles = theme => ({
  card: {
    display: "flex",
    marginBottom: 10,
    maxWidth: 300
  },
  details: {
    display: "flex",
    flexDirection: "column"
  },
  content: {
    flex: "1 0 auto"
  },
  cover: {
    width: 120,
    height: 120
  },
  gold: {
    color: amber[600]
  }
});

class UserList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      users: props.users,
      showSelect: props.allowSelect,
      achievmentDialogOpen: false,
      rewardDialogOpen: false,
      selectedUsers: {}
    };

    this.onSelectionChange = props.onSelectionChange;
  }

  static getDerivedStateFromProps(props) {
    return {
      users: props.users,
      showSelect: props.allowSelect
    };
  }

  handleSelect = event => {
    var selectedUsers = this.state.selectedUsers;
    selectedUsers[event.target.name] = event.target.checked;
    this.setState({
      selectedUsers: selectedUsers
    });

    this.onSelectionChange(
      Object.keys(selectedUsers).filter(u => selectedUsers[u] === true)
    );
  };

  openAchievementDialog = () => {
    this.setState({ achievmentDialogOpen: true });
  };

  closeAchievementDialog = () => {
    this.setState({ achievmentDialogOpen: false });
  };

  openRewardDialog = () => {
    this.setState({ rewardDialogOpen: true });
  };

  closeRewardDialog = () => {
    this.setState({ rewardDialogOpen: false });
  };

  awardAchievement = achievementId => {
    Object.keys(this.state.selectedUsers)
      .filter(u => this.state.selectedUsers[u] === true)
      .forEach(uid => {
        this.props.firebase
          .userAchievement(uid, achievementId)
          .set({
            awarded: true,
            awarded_by: this.props.firebase.auth.currentUser.uid
          })
          .then(result => {
            this.props.enqueueSnackbar("Achievement awarded", {
              variant: "success"
            });
          })
          .catch(err => {
            this.props.enqueueSnackbar("Achievement could not be awarded", {
              variant: "error"
            });
          });
      });

    this.closeAchievementDialog();
  };

  rewardXP = rewardId => {
    Object.keys(this.state.selectedUsers)
      .filter(u => this.state.selectedUsers[u] === true)
      .forEach(uid => {
        this.props.firebase
          .userRewards(uid)
          .add({
            rewardId: rewardId,
            awarded: true,
            awarded_by: this.props.firebase.auth.currentUser.uid
          })
          .then(result => {
            this.props.enqueueSnackbar("Reward given", { variant: "success" });
          })
          .catch(err => {
            this.props.enqueueSnackbar("Achievement could not be awarded", {
              variant: "error"
            });
          });
      });
    this.closeRewardDialog();
  };

  render() {
    const { classes } = this.props;
    const {
      users,
      showSelect,
      selectedUsers,
      achievmentDialogOpen,
      rewardDialogOpen
    } = this.state;
    return (
      <>
        {showSelect && (
          <>
            <EnhancedTableToolbar
              numSelected={
                Object.keys(selectedUsers).filter(
                  u => selectedUsers[u] === true
                ).length
              }
              addAchievement={this.openAchievementDialog}
              rewardXP={this.openRewardDialog}
            />
            <AchievementPicker
              open={achievmentDialogOpen}
              handleClose={this.closeAchievementDialog}
              awardAchievement={this.awardAchievement}
            />
            <RewardPicker
              open={rewardDialogOpen}
              handleClose={this.closeRewardDialog}
              awardReward={this.rewardXP}
            />
          </>
        )}
        <Table aria-label="Levels">
          <TableHead>
            <TableRow>
              {showSelect && <TableCell></TableCell>}
              <TableCell></TableCell>
              <TableCell>Name</TableCell>
              <TableCell>XP</TableCell>
              <TableCell>Level</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map(user => (
              <TableRow key={user.id}>
                {showSelect && (
                  <TableCell padding="checkbox">
                    <Checkbox name={user.id} onChange={this.handleSelect} />
                  </TableCell>
                )}
                <TableCell padding="checkbox">
                  <Badge
                    className={classes.gold}
                    overlap="circle"
                    anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                    badgeContent={<FontAwesomeIcon icon="crown" />}
                    invisible={!user.roles || !user.roles[ROLES.ADMIN]}
                  >
                    <Avatar src={user.photoURL} />
                  </Badge>
                </TableCell>
                <TableCell>
                  <Link href={ROUTES.VIEWUSER.replace(":userId", user.id)}>
                    {user.username}
                  </Link>
                </TableCell>
                <TableCell>{user.xp}</TableCell>
                <TableCell>{user.level}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </>
    );
  }
}

export default compose(
  withSnackbar,
  withFirebase,
  withStyles(styles)
)(UserList);
