import React from "react";

import { Grid, CircularProgress } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";

const styles = theme => ({
  loading: {
    textAlign: "center",
    margin: 50
  }
});

const Loader = withStyles(styles)(({ classes }) => (
  <Grid container>
    <Grid item xs={12} className={classes.loading}>
      <CircularProgress />
    </Grid>
  </Grid>
));

export default Loader;
