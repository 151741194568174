import React from 'react';
import { AuthUserContext, withAuthorization } from '../../components/Session';

const Home = () => (
    <AuthUserContext.Consumer>
        { authUser => (
        <div>
            <h1>Hello {authUser.username}</h1>
            <p>Only accessible by someone signed in</p>
        </div>
        )}
    </AuthUserContext.Consumer>
);

const condition = authUser => !!authUser;

export default withAuthorization(condition)(Home);