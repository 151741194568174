import React, { Component } from "react";
import Moment from "react-moment";

import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";

import { withFirebase } from "../Firebase";
import { withStyles } from "@material-ui/core/styles";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class MyAchievementsPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      achievements: []
    };
  }

  componentDidMount() {
    this.setState({ loading: true });

    this.unsubscribe = this.props.firebase
      .userAchievements(this.props.userId)
      .orderBy("awarded_on", "desc")
      .onSnapshot(snapshot => {
        var achievements = [];
        snapshot.docs.forEach(doc => {
          achievements.push({
            ...doc.data(),
            id: doc.id
          });
        });
        this.setState({
          achievements: achievements,
          loading: false
        });
      });
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  render() {
    const { achievements, loading } = this.state;

    return (
      <div>
        {loading && <div>Loading...</div>}
        <AchievementList achievements={achievements} />
      </div>
    );
  }
}

const styles = theme => ({
  media: {
    height: 140
  },
  xp: {
    marginTop: 10,
    textAlign: "center"
  },
  avatar: {
    marginTop: 5
  }
});

const AchievementList = withStyles(styles)(({ classes, achievements }) => (
  <Grid container spacing={3}>
    {achievements.map(achievement => (
      <Grid item key={achievement.id} xs={12}>
        <Card className={classes.card}>
          <CardContent>
            <Grid container spacing={1}>
              <Grid item xs>
                <Avatar className={classes.avatar}>
                  <FontAwesomeIcon icon={achievement.icon || "trophy"} />
                </Avatar>
              </Grid>
              <Grid item xs={8}>
                <Typography gutterBottom variant="body1">
                  {achievement.name}
                </Typography>
                <Typography variant="caption">
                  <Moment fromNow>{achievement.awarded_on.toDate()}</Moment>
                </Typography>
              </Grid>
              <Grid item xs={2} className={classes.xp}>
                <Typography variant="h5">{achievement.xp}</Typography>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    ))}
  </Grid>
));

export default withFirebase(MyAchievementsPage);
