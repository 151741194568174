import React from "react";
import { withStyles } from "@material-ui/core/styles";

import Typography from "@material-ui/core/Typography";
import Slider from "@material-ui/core/Slider";

const styles = theme => ({
  root: {
    width: "90%"
  }
});
const marks = [
  {
    value: 100,
    label: "100"
  },
  {
    value: 200,
    label: "200"
  },
  {
    value: 300,
    label: "300"
  },
  {
    value: 400,
    label: "400"
  },
  {
    value: 500,
    label: "500"
  },
  {
    value: 600,
    label: "600"
  },
  {
    value: 700,
    label: "700"
  },
  {
    value: 800,
    label: "800"
  },
  {
    value: 900,
    label: "900"
  },
  {
    value: 1000,
    label: "1000"
  }
];

function valuetext(value) {
  return "hello";
}

class AdminLevelPage extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <Typography id="discrete-slider" variant="h2" gutterBottom>
          Levels
        </Typography>
        <Slider
          defaultValue={[100, 200, 300, 400, 500, 600, 700, 800]}
          aria-valuetext={valuetext}
          aria-labelledby="discrete-slider"
          valueLabelDisplay="on"
          step={10}
          marks={marks}
          min={0}
          max={1000}
        />
      </div>
    );
  }
}

export default withStyles(styles)(AdminLevelPage);
