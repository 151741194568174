import React, { useState } from "react";
import { withFirebase } from "../Firebase";

import Button from "@material-ui/core/Button";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import ActivityButton from "../ActivityButton";

const fab = {
  margin: "0px",
  top: "auto",
  right: "20px",
  bottom: "20px",
  left: "auto",
  position: "fixed"
};

const initialState = {
  open: false,
  errors: {
    name: ""
  },
  valid: false,
  loading: false,
  success: false
};

function NewOrganisation(props) {
  const [{ open, valid, errors, loading, success }, setState] = useState(
    initialState
  );
  const [name, setName] = useState("");

  const clearState = () => {
    setState({ ...initialState });
    setName("");
  };

  const onChangeName = event => {
    const { value } = event.target;
    setName(value);
    let tempErrors = errors;
    tempErrors.name = value.length < 4 ? "Must be at least 3 characters" : "";
    setState(prevState => ({
      ...prevState,
      tempErrors,
      valid: validateForm()
    }));
  };

  const validateForm = () => {
    return !errors.name.length > 0;
  };

  const handleClickOpen = () => {
    setState(prevState => ({ ...prevState, open: true }));
  };

  const handleClose = () => {
    setState(prevState => ({ ...prevState, open: false }));
  };

  const handleSubmit = event => {
    setState(prevState => ({ ...prevState, loading: true }));
    if (valid) {
      console.log(props.authUser.uid);
      props.firebase
        .organisations()
        .add({ name, admins: [props.authUser.uid] })
        .then(clearState);

      setState(prevState => ({ ...prevState, loading: false, success: true }));
      console.info("Valid Form");
    } else {
      console.error("Invalid Form");
      setState(prevState => ({ ...prevState, loading: false }));
    }
  };

  return (
    <div>
      <Fab
        color="primary"
        aria-label="add"
        style={fab}
        onClick={handleClickOpen}>
        <AddIcon />
      </Fab>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Create an Organisation</DialogTitle>
        <DialogContent>
          <DialogContentText>
            A new Organisation is where you can make the magic happen
          </DialogContentText>
          <TextField
            autoFocus
            error={!!errors.name}
            margin="dense"
            id="name"
            label={"Organisation Name"}
            type="name"
            onChange={onChangeName}
            value={name}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <ActivityButton
            buttonName="Create"
            onClick={handleSubmit}
            loading={loading}
            success={success}
          />
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default withFirebase(NewOrganisation);
