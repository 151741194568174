import React, { PureComponent } from 'react'
import MaterialTable from 'material-table'
import { withSnackbar } from 'notistack';
import { withFirebase } from "../Firebase";

import AuthUserContext from "../../components/Session/context"
import TextField from '@material-ui/core/TextField';

import * as ROLES from "../../constants/roles"

const TextInput = ({props}) => {
  return (
  <TextField
    fullWidth
    multiline
    inputProps={props}
    value={props.value}
    onChange={e => props.onChange(e.target.value)} />
)}

class AchievementList extends PureComponent {
  render() {
    return (
      <div style={{ maxWidth: '100%' }}>
        <AuthUserContext.Consumer>
          {authUser =>
            <MaterialTable
              options={{
                showEmptyDataSourceMessage: false,
              }}
              columns={[
                // { title: 'Image', field: 'photoURL',
                //   render: rowData => <img src={rowData.photoURL} style={{width: 50, borderRadius: '50%'}} alt={rowData.name}/>,
                //   editComponent: props => <TextInput props={props} />
                // },
                { title: 'Name', field: 'name',
                  editComponent: props => <TextInput props={props} />
                },
                { title: 'Description', field: 'description',
                  editComponent: props => <TextInput props={props} />
                },
                { title: 'XP', field: 'xp', type: 'numeric' },
              ]}
              data={this.props.achievements}
              isLoading={this.props.loading}
              title="Achievement list"
              editable={{
                isEditable: () => !!authUser.roles[ROLES.ADMIN],
                isDeletable: () => !!authUser.roles[ROLES.ADMIN],
                onRowUpdate: (newData, oldData) =>
                  new Promise((resolve, reject) => {
                    this.props.firebase
                    .achievements()
                    .doc(oldData.id)
                    .update({
                      name: newData.name,
                      xp: newData.xp,
                      description: newData.description,
                      photoURL: newData.photoURL
                    })
                    .then(() => {
                      this.props.enqueueSnackbar('Achievement updated', {variant: 'success'})
                      resolve()
                    }).catch((err) => {
                      this.props.enqueueSnackbar('Edit unsuccessful', {variant: 'error'})
                      reject()
                    })
                  }),
                onRowDelete: oldData =>
                  new Promise((resolve, reject) => {
                    this.props.firebase
                      .achievements()
                      .doc(oldData.id)
                      .delete()
                      .then(() => {
                        this.props.enqueueSnackbar('Achievement deleted', {variant: 'success'})
                        resolve()
                      }).catch((err) => {
                        this.props.enqueueSnackbar('Delete unsuccessful', {variant: 'error'})
                        reject()
                      })
                  })
            }}
            />
          }
        </AuthUserContext.Consumer>
      </div>
    )
  }
}

export default withSnackbar(withFirebase(AchievementList))
