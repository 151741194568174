import React from "react";
import Paper from "@material-ui/core/Paper";

import { withFirebase } from "../../components/Firebase";
import { OrganisationsList } from ".";
import { Typography } from "@material-ui/core";

class MyOrganisations extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      organisations: []
    };
  }

  componentDidMount() {
    this.setState({ loading: true });
    this.unsubscribe = this.props.firebase
      .userOrganisations(this.props.authUser.uid)
      .onSnapshot(snapshot => {
        var organisations = [];
        snapshot.docs.forEach(doc => {
          organisations.push({
            ...doc.data(),
            id: doc.id
          });
        });
        this.setState({
          organisations: organisations,
          loading: false
        });
      });
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  render() {
    const { organisations, loading } = this.state;
    return (
      <>
        {loading && (
          <Typography variant="h5" gutterBottom>
            Loading...
          </Typography>
        )}
        {organisations.length === 0 && !loading && (
          <Typography variant="h5" gutterBottom>
            You don't have any Organisations, why not create one?
          </Typography>
        )}
        <Paper>
          {organisations.length > 0 && !loading && (
            <OrganisationsList organisations={organisations} />
          )}
        </Paper>
      </>
    );
  }
}

export default withFirebase(MyOrganisations);
