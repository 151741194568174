import React, { Component } from "react";
import Avatar from "@material-ui/core/Avatar";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import { withStyles } from "@material-ui/core/styles";

import Moment from "react-moment";

import { withFirebase } from "../Firebase";

class MyXpPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      xp: []
    };
  }

  componentDidMount() {
    this.setState({ loading: true });

    this.unsubscribe = this.props.firebase
      .userRewards(this.props.userId)
      .orderBy("awarded_on", "desc")
      .onSnapshot(snapshot => {
        var xp = [];
        snapshot.docs.forEach(doc => {
          xp.push({
            ...doc.data(),
            id: doc.id
          });
        });
        this.setState({
          xp: xp,
          loading: false
        });
      });
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  render() {
    const { xp, loading } = this.state;

    return (
      <div>
        {loading && <div>Loading...</div>}
        <XPList xp={xp} />
      </div>
    );
  }
}

const styles = theme => ({
  title: {
    paddingTop: 13,
    paddingBottom: 10
  },
  date: {
    paddingTop: 7
  },
  divider: {
    marginBottom: 20
  },
  avatar: {
    marginTop: 5
  }
});

const XPList = withStyles(styles)(({ classes, xp }) => (
  <Grid container>
    {xp.map(xp => (
      <Grid item key={xp.id} xs={12}>
        <Grid container spacing={4}>
          <Grid item xs={2}>
            <Avatar className={classes.avatar}>{xp.xp}</Avatar>
          </Grid>
          <Grid item xs={10}>
            <Typography gutterBottom variant="body1">
              {xp.name}
            </Typography>
            <Typography variant="caption">
              <Moment fromNow>{xp.awarded_on.toDate()}</Moment>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    ))}
  </Grid>
));

export default withFirebase(MyXpPage);
