import React from "react";
import { withStyles } from "@material-ui/core/styles";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Avatar from "@material-ui/core/Avatar";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";

import TrendingUpIcon from "@material-ui/icons/TrendingUp";
import ArchiveIcon from "@material-ui/icons/Archive";

const styles = () => {
  return {
    textCenter: {
      textAlign: "center"
    }
  };
};

class XpList extends React.Component {
  render() {
    const { classes, rewards, showAdminFunctions, archiveReward } = this.props;
    return (
      <Paper>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox"></TableCell>
              <TableCell>XP</TableCell>
              <TableCell>Reward description</TableCell>
              {showAdminFunctions && (
                <TableCell className={classes.textCenter}>Archive</TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {rewards.map(r => (
              <TableRow key={r.id}>
                <TableCell>
                  <Avatar>
                    <TrendingUpIcon />
                  </Avatar>
                </TableCell>
                <TableCell>{r.xp}</TableCell>
                <TableCell>{r.name}</TableCell>
                {showAdminFunctions && (
                  <TableCell className={classes.textCenter}>
                    <IconButton
                      id={r.id}
                      aria-label="archive"
                      onClick={() => archiveReward(r.id)}
                      color="inherit"
                    >
                      <ArchiveIcon id={r.id} />
                    </IconButton>
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
    );
  }
}

export default withStyles(styles)(XpList);
