import React from "react";
import { withRouter } from "react-router";

import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Badge from "@material-ui/core/Badge";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { AuthUserContext } from "../../components/Session";
import MyAchievementList from "../../components/Me/myAchievements";
import MyXpList from "../../components/Me/myXp";
import MySpecialitiesList from "../../components/Me/mySpecialities";
import Loader from "../../components/Loader";
import AdminButtons from "../../components/User/adminButtons";

import { useUserDetails } from "../../components/Firebase";

import * as ROUTES from "../../constants/routes";
import * as ROLES from "../../constants/roles";

const styles = theme => ({
  profile: {
    textAlign: "center",
    marginTop: 30
  },
  profilePhoto: {
    borderRadius: "50%",
    height: 130
  },
  lastItem: {
    marginBottom: 30
  },
  columnTitle: {
    marginBottom: 40
  }
});

const ViewUserPage = ({ match, history, classes }) => {
  const { user, loading, error, toggleAdmin } = useUserDetails(
    match.params.userId
  );

  if (error) {
    history.push(ROUTES.TEAM);
  }
  return (
    <>
      {loading && <Loader />}
      {!loading && (
        <Grid container spacing={5}>
          <Grid item xs={12} md={3} className={classes.profile}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Badge
                  badgeContent={<FontAwesomeIcon icon="crown" />}
                  overlap="circle"
                  color="primary"
                  anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                  invisible={!user.roles || !user.roles[ROLES.ADMIN]}
                >
                  <img
                    alt="profile"
                    className={classes.profilePhoto}
                    src={user.photoURL}
                  ></img>
                </Badge>
              </Grid>
              <AuthUserContext.Consumer>
                {adminUser =>
                  match.params.userId !== adminUser.uid &&
                  !!adminUser.roles[ROLES.ADMIN] && (
                    <Grid item xs={12}>
                      <AdminButtons user={user} toggleAdmin={toggleAdmin} />
                    </Grid>
                  )
                }
              </AuthUserContext.Consumer>
              <Grid item xs={12}>
                <Typography variant="h4" gutterBottom>
                  {user.username}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h5" gutterBottom>
                  {user.level}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  variant="h5"
                  gutterBottom
                  className={classes.lastItem}
                >
                  {user.xp} xp
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <MySpecialitiesList userId={match.params.userId} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography
              variant="h4"
              gutterBottom
              className={classes.columnTitle}
            >
              Achievements
            </Typography>
            <MyAchievementList userId={match.params.userId} />
          </Grid>
          <Grid item xs={12} md={3}>
            <Typography
              variant="h4"
              gutterBottom
              className={classes.columnTitle}
            >
              Others...
            </Typography>
            <MyXpList userId={match.params.userId} />
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default withRouter(withStyles(styles)(ViewUserPage));
