import React, { useState } from 'react';
import { withFirebase } from "../../components/Firebase";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { ActivityButton, ErrorDialog } from '..';
import { makeStyles } from '@material-ui/core/styles';

import * as ROUTES from "../../constants/routes";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://material-ui.com/">
        Skilltrees.io
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles(theme => ({
  root: {
    height: '100vh',
  },
  image: {
    backgroundImage: 'url(https://source.unsplash.com/random)',
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'dark' ? theme.palette.grey[900] : theme.palette.grey[50],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

function SignInSide(props) {
  const classes = useStyles();
  const [errors, setError] = useState("")
  const [loading, setLoading] = useState(false)
  const [openError, setOpenError] = useState(false)
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")

  const handleErrorClose = () => {
    setOpenError(false)
  }

  const onSubmitGoogle = event => {
    props.firebase
    .doSignInWithGoogle()
      .then(socialAuthUser => {
        return props.firebase.user(socialAuthUser.user.uid).set({
          username: socialAuthUser.user.displayName,
          email: socialAuthUser.user.email,
          photoURL: socialAuthUser.user.photoURL
        }, {merge: true});
      })
      .then(() => {
        setLoading(false)
        setError(null);
        props.history.push(ROUTES.HOME);
      })
      .catch(error => {
        setLoading(false)
        setError(error.message);
        setOpenError(true)
      });
    if (event) event.preventDefault();
  }

  const onSubmit = event => {
    setLoading(true)
    props.firebase
      .doSignInWithEmail(email, password)
      .then(() => {
        setLoading(false)
        setError(null);
        props.history.push(ROUTES.HOME);
      })
      .catch(error => {
        setLoading(false)
        setError(error.message);
        setOpenError(true)
      });

    if (event) event.preventDefault();
  };

  const validation = () => {
    return !(!!email && !!password)
  }

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} sm={4} md={7} className={classes.image} />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <form className={classes.form} noValidate>
            <TextField
              autoFocus
              margin="normal"
              variant="outlined"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              value={email}
              onChange={e => setEmail(e.target.value)}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              value={password}
              onChange={e => setPassword(e.target.value)}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  onSubmit()
                }
              }}
            />
            <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            />
            <ActivityButton
              onClick={onSubmit}
              disabled={validation()}
              loading={loading}
              fullWidth={true}
              variant="contained"
              color="primary"
              className={classes.submit}
              buttonName="Sign In"
            />
            <div style={{display: "flex", alignItems: 'center', justifyContent: 'center'}}>
              <Button variant="stretch" style={{width: 200, height: 75, padding: 0, marginBottom: 10}} >
                <img src="/images/googleSignIn.png" alt={'signInWithGoogle'} style={{flex: 1, height: '100%', width: '100%'}} onClick={onSubmitGoogle}/>
              </Button>
            </div>
            <Grid container>
              <Grid item xs>
                <Link variant="body2" onClick={() => props.onClick('ForgotPass', {email})} style={{cursor: "pointer"}}>
                  Forgot password?
                </Link>
              </Grid>
              <Grid item>
                <Link variant="body2" onClick={() => props.onClick('SignUp')} style={{cursor: "pointer"}}>
                  {"Don't have an account? Sign Up"}
                </Link>
              </Grid>
            </Grid>
            <Box mt={5}>
              <Copyright />
            </Box>
          </form>
        </div>
      </Grid>
      <ErrorDialog handleClose={handleErrorClose} openDialog={openError} errorMessage={errors} />
    </Grid>
  );
}

const SignIn = compose(withRouter, withFirebase)(SignInSide);

export default SignIn