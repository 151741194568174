import React, { Component } from "react";

import { withFirebase } from "../../components/Firebase";
import { LevelsList } from "../../components/Levels";

class LevelsPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      levels: [],
    };
  }

  componentDidMount() {
    this.setState({ loading: true });

    this.unsubscribe = this.props.firebase
      .namedOrganisation("Skilltrees")
      .onSnapshot(snapshot => {
        var levels = [];
        levels = snapshot.docs[0].data().levels || []
        this.setState({
          levels: levels,
          loading: false
        });
      });
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  render() {
    const { levels, loading } = this.state;

    return (
      <div>
        <h1>Levels</h1>
        <LevelsList levels={levels} loading={loading} />
      </div>
    );
  }
}

export default withFirebase(LevelsPage);
