import React, { useState } from 'react';
import {SignIn, SignUp, ForgotPassword} from '../../components'

export default function SignInPage() {

  const [showSignIn, setShowSignIn] = useState(true)
  const [showForgot, setShowForgot] = useState(false)
  const [signInProps, setProps] = useState({})

  const handleClick = (event, props) => {
    if (props) setProps(props)
    switch(event) {
      case 'SignUp':
        setShowSignIn(false)
        break;
      case 'ForgotPass':
        setShowForgot(true)
        break;
      case 'SignIn':
      default:
        setShowForgot(false)
        setShowSignIn(true)
        break;
    }
  }

  if(showForgot) {
    return <ForgotPassword onClick={handleClick} signInProps={signInProps} />
  } else if (showSignIn) {
    return <SignIn onClick={handleClick} />
  } else {
    return <SignUp onClick={handleClick} />
  }

}