import React, { Component } from "react";
import { compose } from "recompose";

import { withFirebase } from "../../components/Firebase";
import { withAuthorization } from "../../components/Session";
import * as ROLES from "../../constants/roles";

class AdminPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      users: []
    };
  }

  componentDidMount() {
    this.setState({ loading: true });

    this.unsubscribe = this.props.firebase.users().onSnapshot(snapshot => {
      var users = [];
      snapshot.docs.forEach(doc =>
        users.push({
          ...doc.data(),
          id: doc.id
        })
      );
      this.setState({
        users: users,
        loading: false
      });
    });
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  render() {
    const { users, loading } = this.state;

    return (
      <div>
        <h1>Admin</h1>
        {loading && <div>Loading...</div>}
        <UserList users={users} />
      </div>
    );
  }
}

const UserList = ({ users }) => (
  <ul>
    {users.map(user => (
      <li key={user.id}>
        <span>
          <strong>ID:</strong> {user.id}
        </span>
        <span>
          <strong>Name:</strong> {user.username}
        </span>
        <span>
          <strong>E-Mail:</strong> {user.email}
        </span>
      </li>
    ))}
  </ul>
);

const condition = authUser => authUser && !!authUser.roles[ROLES.ADMIN];

export default compose(withAuthorization(condition), withFirebase)(AdminPage);
