import React from "react";

import clsx from "clsx";
import { lighten, makeStyles } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const useToolbarStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1)
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85)
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark
        },
  title: {
    flex: "1 1 100%"
  }
}));

const EnhancedTableToolbar = props => {
  const classes = useToolbarStyles();
  const { numSelected, addAchievement, rewardXP } = props;

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0
      })}
    >
      <Typography className={classes.title} color="inherit" variant="subtitle1">
        {numSelected} selected
      </Typography>

      {numSelected > 0 && (
        <>
          <Tooltip title="Award achievement">
            <IconButton aria-label="Award achievement" onClick={addAchievement}>
              <FontAwesomeIcon icon="trophy" />
            </IconButton>
          </Tooltip>
          <Tooltip title="Reward XP">
            <IconButton aria-label="Reward XP" onClick={rewardXP}>
              <FontAwesomeIcon icon="award" />
            </IconButton>
          </Tooltip>
        </>
      )}
    </Toolbar>
  );
};

export default EnhancedTableToolbar;
