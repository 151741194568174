import React from "react";
import { withFirebase } from "../Firebase";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";

class AchievementPicker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      achievements: [],
      selectedAchievement: "",
      disableAward: false
    };

    this.onChange = props.onChange;
  }

  componentDidMount() {
    this.setState({ loading: true });

    this.unsubscribe = this.props.firebase
      .achievements()
      .onSnapshot(snapshot => {
        var achievements = [];
        snapshot.docs.forEach(doc => {
          achievements.push({
            ...doc.data(),
            id: doc.id
          });
        });
        this.setState({
          achievements: achievements,
          loading: false
        });
      });
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  handleSelect = event => {
    this.setState({ selectedAchievement: event.target.value });
  };

  handleAward = () => {
    this.setState({ disableAward: true });
    if (this.state.selectedAchievement !== "") {
      this.props.awardAchievement(this.state.selectedAchievement);
    }
  };

  render() {
    const { open, handleClose } = this.props;
    const { achievements, selectedAchievement, loading } = this.state;

    return (
      <Dialog
        aria-labelledby="form-dialog-title"
        open={open}
        onClose={handleClose}>
        <DialogTitle id="form-dialog-title">Award an achievement</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Award an achievement to selected users...
          </DialogContentText>
          <Select
            disabled={loading}
            value={selectedAchievement}
            onChange={this.handleSelect}>
            {loading && <MenuItem>Loading...</MenuItem>}
            {achievements.map(a => (
              <MenuItem key={a.id} value={a.id}>
                {a.name}
              </MenuItem>
            ))}
          </Select>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="default">
            Cancel
          </Button>
          <Button
            onClick={this.handleAward}
            disabled={selectedAchievement === ""}
            color="primary">
            Award
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withFirebase(AchievementPicker);
