import React, { Component } from "react";
import { compose } from "recompose";

import { withFirebase } from "../../components/Firebase";
import { withAuthorization, AuthUserContext } from "../../components/Session";

import * as ROLES from "../../constants/roles";
import { UserList } from "../../components/User";
import Loader from "../../components/Loader";

import { Typography, Paper, Grid } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";

const styles = theme => ({
  loading: {
    textAlign: "center",
    margin: 30
  }
});
class TeamPage extends Component {
  constructor(props) {
    super(props);
    this.handleSelectionChange = this.handleSelectionChange.bind(this);
    this.onAchievementChange = this.onAchievementChange.bind(this);

    this.state = {
      loading: false,
      users: [],
      selectedAchievement: null,
      selectedUsers: []
    };
  }

  componentDidMount() {
    this.setState({ loading: true });

    this.unsubscribe = this.props.firebase.users().onSnapshot(snapshot => {
      var users = [];
      snapshot.docs.forEach(doc =>
        users.push({
          ...doc.data(),
          id: doc.id
        })
      );
      users.sort((a, b) => {
        if (a.xp > b.xp) {
          return -1;
        } else if (a.xp < b.xp) {
          return 1;
        } else {
          return 0;
        }
      });
      this.setState({
        users: users,
        loading: false
      });
    });
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  handleSelectionChange = selectedUsers => {
    this.setState({
      selectedUsers: selectedUsers
    });
  };

  onAchievementChange = achievementId => {
    this.setState({
      selectedAchievement: achievementId
    });
  };

  awardAchievement = () => {
    this.state.selectedUsers.forEach(uid => {
      this.props.firebase
        .userAchievement(uid, this.state.selectedAchievement)
        .set({
          awarded: true,
          awarded_by: this.props.firebase.auth.currentUser.uid
        });
    });
  };

  render() {
    const { users, loading } = this.state;

    return (
      <AuthUserContext.Consumer>
        {authUser => (
          <>
            <div>
              <Typography variant="h2" gutterBottom>
                My team
              </Typography>
              <Grid item md={12} lg={8}>
                <Paper>
                  {loading && <Loader />}
                  {!loading && (
                    <UserList
                      users={users}
                      allowSelect={!!authUser.roles[ROLES.ADMIN]}
                      onSelectionChange={this.handleSelectionChange}
                    />
                  )}
                </Paper>
              </Grid>
            </div>
          </>
        )}
      </AuthUserContext.Consumer>
    );
  }
}

const condition = authUser => !!authUser;

export default compose(
  withAuthorization(condition),
  withFirebase,
  withStyles(styles)
)(TeamPage);
