import React, {useState} from 'react';
import { withFirebase } from "../../components/Firebase";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";

import Avatar from '@material-ui/core/Avatar';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

import * as ROUTES from "../../constants/routes";
import { ActivityButton, ErrorDialog } from '..';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://material-ui.com/">
        WDG Enterprises Ltd
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

function SignUp(props) {
  const classes = useStyles();
  const [errors, setError] = useState("")
  const [loading, setLoading] = useState(false)
  const [openError, setOpenError] = useState(false)
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [marketing, setMarketing] = useState(false)

  const handleErrorClose = () => {
    setOpenError(false)
  }

  const onSubmit = event => {
    setLoading(true)
    props.firebase
      .createAUser(email, password, firstName, lastName, marketing)
      .then(emailAuthUser => {
        const username = firstName + ' ' + lastName
        return props.firebase.user(emailAuthUser.user.uid).set({
          email,
          username,
          firstName,
          lastName,
          marketing
        });
      })
      .then(() => {
        setLoading(false)
        setError(null);
        props.history.push(ROUTES.HOME);
      })
      .catch(error => {
        setLoading(false)
        setError(error.message);
        setOpenError(true)
      });

    event.preventDefault();
  };

  const validation = () => {
    return !(!!firstName && !!lastName && !!validateEmail(email) && !!validatePassword(password))
  }

  const validateEmail = email => {
    // eslint-disable-next-line
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

  const validatePassword = pass => {
    // at least one number, one lowercase and one uppercase letter at least six characters
    var re = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}/;
    return re.test(pass);
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign up
        </Typography>
        <form className={classes.form} noValidate>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                autoComplete="fname"
                name="firstName"
                variant="outlined"
                required
                fullWidth
                id="firstName"
                label="First Name"
                value={firstName}
                onChange={e => setFirstName(e.target.value)}
                autoFocus
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="lastName"
                label="Last Name"
                name="lastName"
                autoComplete="lname"
                value={lastName}
                onChange={e => setLastName(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                error={email.length > 4 && !validateEmail(email)}
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                value={email}
                onChange={e => setEmail(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                error={password !== "" && !validatePassword(password)}
                fullWidth
                helperText={password !== "" && !validatePassword(password) && "At least one number, one lowercase and one uppercase letter at least six characters"}
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                value={password}
                onChange={e => setPassword(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={<Checkbox value="allowMarketing" checked={marketing} color="primary" onChange={e => setMarketing(e.target.checked)} />}
                label="I want to receive inspiration, marketing promotions and updates via email."
              />
            </Grid>
          </Grid>
          <ActivityButton
            onClick={onSubmit}
            disabled={validation()}
            loading={loading}
            fullWidth={true}
            variant="contained"
            color="primary"
            className={classes.submit}
            buttonName="Sign Up"
          />
          <Grid container justify="flex-end">
            <Grid item>
              <Link variant="body2" onClick={() => props.onClick('SignIn')} style={{cursor: "pointer"}}>
                Already have an account? Sign in
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
      <Box mt={5}>
        <Copyright />
      </Box>
      <ErrorDialog handleClose={handleErrorClose} openDialog={openError} errorMessage={errors} />
    </Container>
  );
}

const SignUpComponent = compose(withRouter, withFirebase)(SignUp);

export default withFirebase(SignUpComponent)
