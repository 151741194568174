import React from "react";
import { withSnackbar } from "notistack";
import { withFirebase } from "../Firebase";
import { withStyles } from "@material-ui/core/styles";

import {
  Button,
  Fab,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  TextField,
  CircularProgress
} from "@material-ui/core";

import AddIcon from "@material-ui/icons/Add";

const styles = theme => {
  return {
    fab: {
      margin: 0,
      top: "auto",
      right: 20,
      bottom: 20,
      left: "auto",
      position: "fixed"
    }
  };
};

const initialState = {
  open: false,
  name: "",
  xp: "",
  errors: {
    name: "initial",
    xp: "initial"
  },
  valid: false,
  loading: false
};

class NewReward extends React.Component {
  constructor(props) {
    super(props);
    this.state = initialState;
  }

  openDialog = () => {
    this.setState({ open: true });
  };
  handleClose = () => {
    this.setState({ open: false });
  };
  handleSubmit = () => {
    this.setState(initialState);
  };
  onChangeName = event => {
    const { value } = event.target;
    this.setState({
      name: value,
      errors: {
        ...this.state.errors,
        name: value.length < 5 ? "Must be at least 5 characters" : ""
      },
      valid: this.validateForm()
    });
  };
  onChangeXP = event => {
    const { value } = event.target;
    this.setState({
      xp: value,
      errors: {
        ...this.state.errors,
        xp:
          parseInt(value) < parseInt(1) || parseInt(value) > parseInt(1000)
            ? "The XP must be between 1 and 1000"
            : ""
      },
      valid: this.validateForm()
    });
  };
  handleSubmit = () => {
    if (this.validateForm()) {
      const { name, xp } = this.state;
      this.setState({ open: false, loading: true });
      this.props.firebase
        .xprewards()
        .add({ name, xp, archived: false })
        .then(this.resetState)
        .catch(this.showError);
    }
  };

  validateForm = () => {
    const { errors } = this.state;
    return errors.name.length + errors.xp.length === 0;
  };

  resetState = () => {
    this.setState(initialState);
    this.props.enqueueSnackbar("Reward added!", { variant: "success" });
  };

  showError = err => {
    console.error(err);
    this.openDialog();
    this.setState({ loading: false });
    this.props.enqueueSnackbar("Something went wrong, please try again", {
      variant: "error"
    });
  };

  render() {
    const { classes } = this.props;
    const { open, errors, name, xp, loading } = this.state;
    const {
      handleClose,
      openDialog,
      handleSubmit,
      onChangeName,
      onChangeXP
    } = this;

    return (
      <>
        <Fab
          disabled={loading}
          className={classes.fab}
          color="primary"
          aria-label="add"
          onClick={openDialog}
        >
          {!loading && <AddIcon />}
          {loading && <CircularProgress />}
        </Fab>
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>Add a reward</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Some text here about adding rewards
            </DialogContentText>
            <TextField
              autoFocus
              error={!!errors.name}
              margin="dense"
              id="name"
              label={"Reward Name"}
              type="text"
              onChange={onChangeName}
              value={name}
              fullWidth
            />
            <TextField
              error={!!errors.xp}
              margin="dense"
              id="xp"
              label={"XP"}
              type="number"
              onChange={onChangeXP}
              value={xp}
            />
            <DialogActions>
              <Button onClick={handleClose} color="default">
                Cancel
              </Button>
              <Button onClick={handleSubmit} color="primary">
                Create
              </Button>
            </DialogActions>
          </DialogContent>
        </Dialog>
      </>
    );
  }
}

export default withSnackbar(withFirebase(withStyles(styles)(NewReward)));
