import React from "react";

import { Avatar, IconButton, Tooltip, Menu, MenuItem } from "@material-ui/core";
import { green } from "@material-ui/core/colors";
import { withStyles } from "@material-ui/core/styles";
import MoreIcon from "@material-ui/icons/MoreVert";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import * as ROLES from "../../constants/roles";

const styles = theme => ({
  white: {
    color: theme.palette.getContrastText(green[50]),
    backgroundColor: green[50]
  },
  green: {
    color: theme.palette.getContrastText(green[500]),
    backgroundColor: green[500]
  }
});

function AdminButtons({ user, classes, toggleAdmin }) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const clickAdmin = () => {
    toggleAdmin();
    handleClose();
  };

  return (
    <>
      <Tooltip
        title="Award achievement"
        aria-label="award achievement"
        placement="top"
      >
        <IconButton aria-label="more" edge="end" color="primary">
          <Avatar className={classes.white}>
            <FontAwesomeIcon icon="trophy" />
          </Avatar>
        </IconButton>
      </Tooltip>
      <Tooltip title="Reward XP" aria-label="Reward XP" placement="top">
        <IconButton aria-label="more" edge="end" color="primary">
          <Avatar className={classes.white}>
            <FontAwesomeIcon icon="award" />
          </Avatar>
        </IconButton>
      </Tooltip>
      <Tooltip title="More actions..." aria-label="Make admin" placement="top">
        <IconButton
          aria-label="more"
          onClick={handleClick}
          edge="end"
          color="primary"
        >
          <Avatar className={classes.white}>
            <MoreIcon />
          </Avatar>
        </IconButton>
      </Tooltip>
      <Menu
        id="more-actions-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <MenuItem onClick={clickAdmin}>
          {!user.roles || !user.roles[ROLES.ADMIN]
            ? "Make admin"
            : "Revoke Admin"}
        </MenuItem>
      </Menu>
    </>
  );
}

export default withStyles(styles)(AdminButtons);
