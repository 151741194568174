import React, { useState } from "react";
import { compose } from "recompose";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { SnackbarProvider } from "notistack";

import { withStyles, ThemeProvider } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Drawer from "@material-ui/core/Drawer";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Hidden from "@material-ui/core/Hidden";
import Divider from "@material-ui/core/Divider";

import { AuthUserContext, withAuthentication } from "../../components/Session";
import { AppToolBar, Navigation } from "../../components";

import {
  Achievements,
  Home,
  Levels,
  SignIn,
  Specialities,
  Team,
  Xp,
  ViewUser
} from "../../pages";

import * as ROUTES from "../../constants/routes";

import Theme from "./theme";

const drawerWidth = 240;

const styles = theme => ({
  root: {
    display: "flex"
  },
  flex: {
    flex: 1
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0
    }
  },
  logoIcon: {
    marginRight: theme.spacing(2)
  },
  toolbarMargin: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3)
  }
});

const AppDrawer = withStyles(styles)(({ classes, open, onClose }) => (
  <nav className={classes.drawer}>
    <Hidden smUp implementation="css">
      <Drawer
        variant="temporary"
        open={open}
        onClose={onClose}
        classes={{ paper: classes.drawerPaper }}
      >
        <Navigation />
      </Drawer>
    </Hidden>
    <Hidden xsDown implementation="css">
      <Drawer
        variant="permanent"
        open={open}
        onClose={onClose}
        classes={{ paper: classes.drawerPaper }}
      >
        <Toolbar>
          <img
            alt="logo"
            src="/logo128.png"
            height="30"
            className={classes.logoIcon}
          />
          <Typography variant="h6">Skilltrees</Typography>
        </Toolbar>
        <Divider />
        <Navigation />
      </Drawer>
    </Hidden>
  </nav>
));

function App({ classes }) {
  const [drawer, setDrawer] = useState(false);
  const toggleDrawer = () => {
    setDrawer(!drawer);
  };
  return (
    <ThemeProvider theme={Theme}>
      <SnackbarProvider maxSnack={3}>
        <Router>
          <div className={classes.root}>
            <CssBaseline />
            <AuthUserContext.Consumer>
              {authUser => (
                <>
                  {authUser && (
                    <>
                      <AppToolBar onMenuClick={toggleDrawer} open={drawer} />
                      <AppDrawer open={drawer} onClose={toggleDrawer} />
                      <main className={classes.content}>
                        <div className={classes.toolbarMargin} />
                        <Route exact path={ROUTES.HOME} component={Home} />
                        <Route exact path={ROUTES.TEAM} component={Team} />
                        <Route
                          exact
                          path={ROUTES.ACHIEVEMENTS}
                          component={Achievements}
                        />
                        <Route
                          exact
                          path={ROUTES.SPECIALITIES}
                          component={Specialities}
                        />
                        <Route exact path={ROUTES.LEVELS} component={Levels} />
                        <Route exact path={ROUTES.XP} component={Xp} />
                        <Route path={ROUTES.VIEWUSER} component={ViewUser} />
                      </main>
                    </>
                  )}
                  {authUser === null && <Route component={SignIn} />}
                </>
              )}
            </AuthUserContext.Consumer>
          </div>
        </Router>
      </SnackbarProvider>
    </ThemeProvider>
  );
}

export default compose(withStyles(styles), withAuthentication)(App);
