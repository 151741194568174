import React from "react";
import { withStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import MoreIcon from "@material-ui/icons/MoreVert";
import Avatar from "@material-ui/core/Avatar";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";

import { withFirebase } from "../../components/Firebase";
import { AuthUserContext } from "../Session";
import { Divider } from "@material-ui/core";

const drawerWidth = 240;
const styles = theme => ({
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      display: "none"
    }
  },
  title: {
    flexGrow: 1
  },
  appBar: {
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth
    },
    boxShadow: "none"
  },
  marginRight: {
    marginRight: 15
  }
});

const AppToolBar = withStyles(styles)(
  ({ classes, title, onMenuClick, open, firebase }) => {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = event => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    const signOut = () => {
      handleClose();
      firebase.doSignOut();
    };
    return (
      <>
        <AppBar position="fixed" className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              className={classes.menuButton}
              color="inherit"
              aria-label="menu"
              onClick={onMenuClick}>
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}></Typography>
            <AuthUserContext.Consumer>
              {authUser =>
                !authUser ? (
                  <Button color="primary">Login</Button>
                ) : (
                  <>
                    <Typography variant="h6" className={classes.marginRight}>
                      {authUser.xp}
                    </Typography>
                    <Avatar src={authUser.photoURL} alt="profile" />
                    <IconButton
                      aria-label="more"
                      onClick={handleClick}
                      edge="end"
                      color="inherit">
                      <MoreIcon />
                    </IconButton>
                    <Menu
                      id="menu-user"
                      anchorEl={anchorEl}
                      keepMounted
                      open={Boolean(anchorEl)}
                      onClose={handleClose}>
                      <MenuItem onClick={signOut}>Sign out</MenuItem>
                    </Menu>
                  </>
                )
              }
            </AuthUserContext.Consumer>
          </Toolbar>
          <Divider />
        </AppBar>
      </>
    );
  }
);

export default withFirebase(AppToolBar);
