import React, { Component } from "react";

import { withFirebase } from "../../components/Firebase";
import AuthUserContext from "../../components/Session/context";
import { AchievementList, NewAchievement } from "../../components/Achievements";

// import { withAuthorization } from '../Session';
import * as ROLES from "../../constants/roles";

class AchievementsPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      achievements: [],
      newAchievement: {
        name: null,
        xp: 10
      },
      errors: {
        name: "",
        xp: ""
      }
    };
  }

  componentDidMount() {
    this.setState({ loading: true });

    this.unsubscribe = this.props.firebase
      .achievements()
      .onSnapshot(snapshot => {
        var achievements = [];
        snapshot.docs.forEach(doc => {
          achievements.push({
            ...doc.data(),
            id: doc.id
          });
        });
        this.setState({
          achievements: achievements,
          loading: false
        });
      });
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  render() {
    const { achievements, loading } = this.state;

    return (
      <div>
        <h1>Achievements</h1>
        <AchievementList achievements={achievements} loading={loading} />
        <AuthUserContext.Consumer>
          {authUser =>
            !!authUser.roles[ROLES.ADMIN] && (
              <NewAchievement />
            )
          }
        </AuthUserContext.Consumer>
      </div>
    );
  }
}

export default withFirebase(AchievementsPage);
