import app from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

const config = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGE_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID
};

class Firebase {
  constructor() {
    app.initializeApp(config);

    this.auth = app.auth();
    this.db = app.firestore();

    this.googleProvider = new app.auth.GoogleAuthProvider();
    this.appleProvider = new app.auth.OAuthProvider("apple.com");
    this.appleProvider.addScope("email");
    this.appleProvider.addScope("name");
  }

  // *** AUTH API ***
  doSignInWithGoogle = () => this.auth.signInWithPopup(this.googleProvider);

  createAUser = (email, password) =>
    this.auth.createUserWithEmailAndPassword(email, password);

  doSignInWithEmail = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password);

  doSignInWithApple = () => this.auth.signInWithPopup(this.appleProvider);
  // .then(result => {
  //   // The signed-in user info.
  //   // const user = result.user;
  //   // You can also get the Apple OAuth Access and ID Tokens.
  //   // const accessToken = result.credential.accessToken;
  //   // const idToken = result.credential.idToken;
  //   // Some other stuff...
  //   console.log("Signed in with Apple: ", result)
  // })
  // .catch(error => {
  //   console.log('Error signing in with Apple... ', error)
  // });

  doSignOut = () => this.auth.signOut();

  sendResetEmail = emailAddress =>
    this.auth.sendPasswordResetEmail(emailAddress);

  // *** Merge Auth and DB User API ***
  onAuthUserListener = (next, fallback) =>
    this.auth.onAuthStateChanged(authUser => {
      if (authUser) {
        this.user(authUser.uid).onSnapshot(snapshot => {
          const dbUser = snapshot.data();
          // default no roles
          if (!dbUser.roles) {
            dbUser.roles = {};
          }

          // merge auth and db users to one object
          authUser = {
            uid: authUser.uid,
            email: authUser.email,
            ...dbUser
          };

          next(authUser);
        });
      } else {
        fallback();
      }
    });

  // *** User API ***

  user = uid => this.db.collection("users").doc(uid);

  users = () => this.db.collection("users");

  // *** User achievements API ***

  userAchievements = uid =>
    this.db.collection("users/" + uid + "/achievements");
  userAchievement = (uid, achievementId) =>
    this.db.collection("users/" + uid + "/achievements").doc(achievementId);

  // *** User specialities API ***

  userSpecialities = uid =>
    this.db.collection("users/" + uid + "/specialities");

  // *** User XP API

  userXP = uid => this.db.collection("users/" + uid + "/xp");

  // *** Acheivements API ***

  achievements = () => this.db.collection("achievements");

  // *** Organisations API ***

  organisations = () => this.db.collection("organisations");

  userOrganisations = uid =>
    this.db.collection("organisations").where("admins", "array-contains", uid);

  // This is a hacky implementation to get an org by name, needed for workaround to levels being in org
  namedOrganisation = orgName => 
    this.db.collection("organisations").where("name", "==", orgName)

  // *** Levels API ***

  // Not needed as levels moving to organisations
  // levels = () => this.db.collection("levels");

  // *** Specialities API ***

  specialities = () => this.db.collection("specialities");

  // *** XP API ***

  xprewards = () => this.db.collection("xprewards");

  userRewards = uid => this.db.collection("users/" + uid + "/rewards");
}

export default Firebase;
