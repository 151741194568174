import React from "react";

import { withStyles } from "@material-ui/core/styles";
// import Button from "@material-ui/core/Button";
// import ButtonGroup from "@material-ui/core/ButtonGroup";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { SpecialitiesList } from "../../components/Specialities";
import { AuthUserContext } from "../../components/Session";

const styles = theme => ({
  header: {
    marginBottom: 25
  },
  buttonFilters: {
    textAlign: "right"
  },
  buttonGroup: {
    marginTop: 20
  },
  buttonIcon: {
    fontSize: theme.typography.pxToRem(5)
  }
});

const Specialities = ({ classes }) => (
  <AuthUserContext.Consumer>
    {authUser => (
      <Grid container spacing={5}>
        <Grid item xs={12} md={6}>
          <Typography variant="h2">Specialities</Typography>
        </Grid>
        {/* <Grid item xs={12} md={6} className={classes.buttonFilters}>
      <ButtonGroup
        color="default"
        aria-label="outlined primary button group"
        className={classes.buttonGroup}>
        <Button
          variant="contained"
          startIcon={
            <FontAwesomeIcon icon="star" className={classes.buttonIcon} />
          }>
          Starred
        </Button>
        <Button
          startIcon={
            <FontAwesomeIcon icon="list" className={classes.buttonIcon} />
          }>
          All
        </Button>
      </ButtonGroup>
    </Grid> */}
        <Grid item xs={12}>
          <SpecialitiesList authUser={authUser} />
        </Grid>
      </Grid>
    )}
  </AuthUserContext.Consumer>
);

export default withStyles(styles)(Specialities);
