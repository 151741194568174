import React from "react";
import ReactDOM from "react-dom";

import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import {
  faCloud,
  faCoffee,
  faCheck,
  faUser,
  faList,
  faStar,
  faSadTear,
  faFutbol,
  faTrophy,
  faAward,
  faIdCard,
  faCrown
} from "@fortawesome/free-solid-svg-icons";

import "./index.css";
import * as serviceWorker from "./serviceWorker";

import App from "./pages/App";
import Firebase, { FirebaseContext } from "./components/Firebase";

library.add(
  fab,
  faCloud,
  faCoffee,
  faCheck,
  faUser,
  faList,
  faStar,
  faSadTear,
  faFutbol,
  faTrophy,
  faAward,
  faIdCard,
  faCrown
);

ReactDOM.render(
  <FirebaseContext.Provider value={new Firebase()}>
    <App />
  </FirebaseContext.Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
