import React from "react";
import List from "@material-ui/core/List";
import ListSubheader from "@material-ui/core/ListSubheader";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import HomeIcon from "@material-ui/icons/Home";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import GroupIcon from "@material-ui/icons/Group";
import StarsIcon from "@material-ui/icons/Stars";
import LabelImportantIcon from "@material-ui/icons/LabelImportant";
import SchoolIcon from "@material-ui/icons/School";
import TrendingUpIcon from "@material-ui/icons/TrendingUp";

//import SignOutButton from "../SignOut";
import * as ROUTES from "../../constants/routes";

import { AuthUserContext, withAuthentication } from "../Session";

function ListItemLink(props) {
  return <ListItem button component="a" {...props} />;
}

const Navigation = () => (
  <AuthUserContext.Consumer>
    {authUser => (
      <>
        {authUser ? (
          <>
            <NavigationAuth authUser={authUser} />
          </>
        ) : (
          <NavigationNonAuth />
        )}
      </>
    )}
  </AuthUserContext.Consumer>
);

const NavigationAuth = ({ authUser }) => (
  <List>
    <ListSubheader>My Skilltrees</ListSubheader>
    <ListItemLink href={ROUTES.HOME}>
      <ListItemIcon>
        <HomeIcon />
      </ListItemIcon>
      <ListItemText primary="Home" />
    </ListItemLink>
    <ListItemLink href={ROUTES.VIEWUSER.replace(":userId", authUser.uid)}>
      <ListItemIcon>
        <AssignmentIndIcon />
      </ListItemIcon>
      <ListItemText primary="Me" />
    </ListItemLink>
    <ListItemLink href={ROUTES.TEAM}>
      <ListItemIcon>
        <GroupIcon />
      </ListItemIcon>
      <ListItemText primary="My team" />
    </ListItemLink>
    <Divider />
    <ListSubheader>My Organisation</ListSubheader>
    <ListItemLink href={ROUTES.ACHIEVEMENTS}>
      <ListItemIcon>
        <StarsIcon />
      </ListItemIcon>
      <ListItemText primary="Achievements" />
    </ListItemLink>
    <ListItemLink href={ROUTES.LEVELS}>
      <ListItemIcon>
        <LabelImportantIcon />
      </ListItemIcon>
      <ListItemText primary="Levels" />
    </ListItemLink>
    <ListItemLink href={ROUTES.SPECIALITIES}>
      <ListItemIcon>
        <SchoolIcon />
      </ListItemIcon>
      <ListItemText primary="Specialities" />
    </ListItemLink>
    <ListItemLink href={ROUTES.XP}>
      <ListItemIcon>
        <TrendingUpIcon />
      </ListItemIcon>
      <ListItemText primary="XP" />
    </ListItemLink>
  </List>
);

const NavigationNonAuth = () => (
  <div>
    <ListItemLink href={ROUTES.SIGN_IN}>Sign In</ListItemLink>
  </div>
);

export default withAuthentication(Navigation);
