import React, { Component } from "react";

import Typography from "@material-ui/core/Typography";
import { withAuthorization, AuthUserContext } from "../../components/Session";

import {
  MyOrganisations,
  NewOrganisation
} from "../../components/Organisations";

class OrganisationsPage extends Component {
  render() {
    return (
      <div>
        <Typography variant="h4" gutterBottom>
          My Organisations
        </Typography>
        <AuthUserContext.Consumer>
          {authUser => (
            <>
              <MyOrganisations authUser={authUser} />
              <NewOrganisation authUser={authUser} />
            </>
          )}
        </AuthUserContext.Consumer>
      </div>
    );
  }
}

const condition = authUser => !!authUser;

export default withAuthorization(condition)(OrganisationsPage);
